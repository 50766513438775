/* === BREAKPOINTS ==== */
/* === TYPOGRAPHY ==== */
/* === FONT SIXING ==== */
/* === OTHER MIXINS ==== */
/* === CROSS BROWSER === */
/* === ACCESSIBILITY === */
.header___-1zdDH6S82LfjNW2PsIc8CL {
  position: fixed;
  top: 0px;
  z-index: 3;
  left: 0px;
  width: 100%;
  background: #181818; }
  .header___-1zdDH6S82LfjNW2PsIc8CL .header__top___-ObBbF5hPwfq_XxzOdqtAN {
    height: 85px; }
  .header___-1zdDH6S82LfjNW2PsIc8CL .header__logo___-y2TsSh9jDrvSSGiLwJq52 svg {
    height: 50px; }
  @media (min-width: 1300px) {
    .header___-1zdDH6S82LfjNW2PsIc8CL .header__top___-ObBbF5hPwfq_XxzOdqtAN {
      height: 160px; }
    .header___-1zdDH6S82LfjNW2PsIc8CL .header__logo___-y2TsSh9jDrvSSGiLwJq52 svg {
      height: 100px; }
    .header___-1zdDH6S82LfjNW2PsIc8CL.scrolled___-2s1q-OVMf8uZqGgh49Gimw .header__top___-ObBbF5hPwfq_XxzOdqtAN {
      height: 85px; }
    .header___-1zdDH6S82LfjNW2PsIc8CL.scrolled___-2s1q-OVMf8uZqGgh49Gimw .header__logo___-y2TsSh9jDrvSSGiLwJq52 svg {
      height: 50px; }
    .header___-1zdDH6S82LfjNW2PsIc8CL.scrolled___-2s1q-OVMf8uZqGgh49Gimw div[class*="search_form"] {
      top: 130px; } }

.header__container___-iC-rjgwtri5lvUg6nIOvp {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 480px) {
    .header__container___-iC-rjgwtri5lvUg6nIOvp {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    .header__container___-iC-rjgwtri5lvUg6nIOvp {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (min-width: 1300px) {
    .header__container___-iC-rjgwtri5lvUg6nIOvp {
      padding-left: 120px;
      padding-right: 120px; } }
  @media (min-width: 1300px) {
    .header__container___-iC-rjgwtri5lvUg6nIOvp {
      padding-left: 70px;
      padding-right: 70px; } }

@media (min-width: 1026px) {
  .header__spacer___-2Fp0tpSqumVhf45fOwUWG7 {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; } }

@media (min-width: 1026px) {
  .header__nav___-3HC-powj2csODbdEAEhgbC {
    display: block;
    height: 45px; } }

.header__top___-ObBbF5hPwfq_XxzOdqtAN {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 160px;
  -webkit-transition: height 250ms cubic-bezier(0.4, 1.13, 0.7, 0.95);
  transition: height 250ms cubic-bezier(0.4, 1.13, 0.7, 0.95); }

.header__contact___-1Dlt9OgchlXDWW0QEkMfH2 {
  display: none; }
  @media (min-width: 1026px) {
    .header__contact___-1Dlt9OgchlXDWW0QEkMfH2 {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      display: block; } }

.header__options___-25ZVKXbvrab1F5JQKuxT0L {
  height: 100%;
  display: none; }
  @media (min-width: 1026px) {
    .header__options___-25ZVKXbvrab1F5JQKuxT0L {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; } }

@media (min-width: 1026px) {
  .header__options__lang___-2K72CTaXw1sLoE9K1FF7dX,
  .header__options__currency___-2GCWzDTZwv2FYYCdhO8-yX,
  .header__options__icon___-2Zp5uRWxt6bYxbsva8NH1q {
    margin-right: 25px; } }

.header__logo___-y2TsSh9jDrvSSGiLwJq52 {
  -webkit-transition: all 250ms cubic-bezier(0.4, 1.13, 0.7, 0.95);
  transition: all 250ms cubic-bezier(0.4, 1.13, 0.7, 0.95);
  text-align: center;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out; }
  .header__logo___-y2TsSh9jDrvSSGiLwJq52:hover {
    opacity: 0.7; }
  .header__logo___-y2TsSh9jDrvSSGiLwJq52 svg {
    -webkit-transition: all 250ms cubic-bezier(0.4, 1.13, 0.7, 0.95);
    transition: all 250ms cubic-bezier(0.4, 1.13, 0.7, 0.95);
    width: 115px;
    height: 40px; }
    .header__logo___-y2TsSh9jDrvSSGiLwJq52 svg path,
    .header__logo___-y2TsSh9jDrvSSGiLwJq52 svg rect {
      fill: #ffffff; }
  @media (min-width: 480px) {
    .header__logo___-y2TsSh9jDrvSSGiLwJq52 svg {
      width: 150px;
      height: 100px; } }
  @media (min-width: 1026px) {
    .header__logo___-y2TsSh9jDrvSSGiLwJq52 svg {
      width: 250px; } }

.header__contact___-1Dlt9OgchlXDWW0QEkMfH2 {
  display: none; }
  .header__contact___-1Dlt9OgchlXDWW0QEkMfH2 a,
  .header__contact___-1Dlt9OgchlXDWW0QEkMfH2 span {
    color: #ffffff;
    font-size: 0.9375rem;
    line-height: 1.125rem; }
  .header__contact___-1Dlt9OgchlXDWW0QEkMfH2:hover path {
    fill: #f6891f; }
  @media (min-width: 1026px) {
    .header__contact___-1Dlt9OgchlXDWW0QEkMfH2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; } }

.header__contact__icon___-2cByYqS_PUo4uhBiczrjSa {
  display: block;
  margin-right: 10px; }
  .header__contact__icon___-2cByYqS_PUo4uhBiczrjSa path {
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out; }

.header__contact__numbers___-1MKWz1sLBlhkvsJxFqKZOV {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .header__contact__numbers___-1MKWz1sLBlhkvsJxFqKZOV a {
    font-family: "Akkurat", sans-serif;
    font-weight: bold;
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    font-size: 0.8125rem;
    line-height: 0.8125rem; }
    .header__contact__numbers___-1MKWz1sLBlhkvsJxFqKZOV a:hover {
      color: #f6891f; }
      .header__contact__numbers___-1MKWz1sLBlhkvsJxFqKZOV a:hover:after {
        color: #ffffff; }
  .header__contact__numbers___-1MKWz1sLBlhkvsJxFqKZOV a:first-child {
    margin-right: 20px; }
    .header__contact__numbers___-1MKWz1sLBlhkvsJxFqKZOV a:first-child:after {
      content: "|";
      position: relative;
      left: 10px; }
  @media (min-width: 1180px) {
    .header__contact__numbers___-1MKWz1sLBlhkvsJxFqKZOV a {
      font-size: 0.9375rem;
      line-height: 0.9375rem; } }

.header__options__lc_link___-WTfIAuipso4lxME0TPvWP {
  background: #f6891f;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  min-width: 40px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: 25px;
  cursor: pointer;
  -webkit-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
  color: #ffffff;
  font-family: "Akkurat", sans-serif;
  font-weight: bold;
  display: none; }
  .header__options__lc_link___-WTfIAuipso4lxME0TPvWP:hover {
    opacity: 0.7; }
  @media (min-width: 1180px) {
    .header__options__lc_link___-WTfIAuipso4lxME0TPvWP {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }

.header__menu_icon___-buOcswPkT-Bl-enyaS7YN {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%; }
  .header__menu_icon___-buOcswPkT-Bl-enyaS7YN button {
    margin-right: 30px;
    height: 26px; }
  @media (min-width: 1026px) {
    .header__menu_icon___-buOcswPkT-Bl-enyaS7YN {
      display: none; } }

.header__mobile_booking___-U8mBpw2qiOUyk107UKZpX {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: right; }
  @media (min-width: 1026px) {
    .header__mobile_booking___-U8mBpw2qiOUyk107UKZpX {
      display: none; } }

.header__options__search___-2H6aIFlZQJl84gfIhOmWRp {
  position: absolute;
  top: 0px;
  left: 80px;
  height: 100%; }
  @media (min-width: 480px) {
    .header__options__search___-2H6aIFlZQJl84gfIhOmWRp {
      left: 100px; } }
  @media (min-width: 768px) {
    .header__options__search___-2H6aIFlZQJl84gfIhOmWRp {
      left: 120px; } }
  @media (min-width: 1026px) {
    .header__options__search___-2H6aIFlZQJl84gfIhOmWRp {
      left: unset;
      position: relative; } }

.header__options__icon___-2Zp5uRWxt6bYxbsva8NH1q {
  position: relative;
  z-index: 1;
  cursor: pointer;
  width: 22px;
  height: 100%; }
  .header__options__icon___-2Zp5uRWxt6bYxbsva8NH1q svg {
    height: 100%;
    width: 100%; }
    .header__options__icon___-2Zp5uRWxt6bYxbsva8NH1q svg circle,
    .header__options__icon___-2Zp5uRWxt6bYxbsva8NH1q svg line {
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
      stroke: #ffffff; }
  .header__options__icon___-2Zp5uRWxt6bYxbsva8NH1q:hover circle,
  .header__options__icon___-2Zp5uRWxt6bYxbsva8NH1q:hover line {
    stroke: #f6891f; }
  .header__options__icon___-2Zp5uRWxt6bYxbsva8NH1q.active___-nLyEeSoETcKdIznQytPu2 circle,
  .header__options__icon___-2Zp5uRWxt6bYxbsva8NH1q.active___-nLyEeSoETcKdIznQytPu2 line {
    stroke: #f6891f; }
  .header__options__icon___-2Zp5uRWxt6bYxbsva8NH1q.active___-nLyEeSoETcKdIznQytPu2:hover {
    opacity: 0.7; }

.header__options__search__form___-3GP2kJRZwoMg0A-56SkiT4 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 1; }

html[class*="accessible"] .header___-1zdDH6S82LfjNW2PsIc8CL {
  position: relative;
  background-color: #ffffff;
  border-bottom: 1px solid #000000;
  padding: 10px; }
  html[class*="accessible"] .header___-1zdDH6S82LfjNW2PsIc8CL .header__top___-ObBbF5hPwfq_XxzOdqtAN {
    height: 85px; }
  html[class*="accessible"] .header___-1zdDH6S82LfjNW2PsIc8CL .header__logo___-y2TsSh9jDrvSSGiLwJq52 svg {
    height: 50px; }

html[class*="accessible"] .header__nav___-3HC-powj2csODbdEAEhgbC {
  height: auto; }

@media (min-width: 1026px) {
  html[class*="accessible"] .header__contact___-1Dlt9OgchlXDWW0QEkMfH2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; } }

html[class*="accessible"] .header__container___-iC-rjgwtri5lvUg6nIOvp {
  padding: 0px; }

html[class*="accessible"] .header__menu_icon___-buOcswPkT-Bl-enyaS7YN {
  display: none; }

html[class*="accessible"] .header__logo___-y2TsSh9jDrvSSGiLwJq52 svg path,
html[class*="accessible"] .header__logo___-y2TsSh9jDrvSSGiLwJq52 svg rect {
  fill: #000000; }
